import React from 'react';

import Box from 'components/Box'
import Text from 'components/Text'

const DemoPage = () => {
  return (
    <Box textAlign="center" pt="2em">
      <Text my="1em" fontSize="2em" fontWeight="bold">教學影片</Text>
      <Box as="video" src="/demo.mp4" controls mx="auto" width="100%" maxWidth="960px">
        <a href="/demo.mp4">下載教學影片</a>
      </Box>
    </Box>
  )
};

export default DemoPage;
